import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Layout from "./Layout";


export default function App() {

    return (    <Switch>
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <Route component={Layout} />
    </Switch>)
}
