import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import SheetFetch from "../../components/sheetFetch";
import {Link, Route, Switch} from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";

// const url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRd96ByJ62JaGj8fVEvyVCNKkttuTnPPlFvKD-ZFFpMRXd0r_PxaqLejX6j_AY12NKaFpS7QAA_gjCz/pub?gid=0&single=true&output=csv';
// const url  = '/csv/work.csv';

const url = 'https://script.google.com/macros/s/AKfycbw1tdbghKWMpfwYjR4GwSXe9Ia4XWmZK9g3FRUimGtCv8qPnIq4/exec?sheet=work';

export default function Work() {
    const classes = useStyles();

    const data = SheetFetch({url});


    function Card(props) {
        let hit = props.match.params.hit;

        useEffect(()=>{
            const anchor = document.querySelector('#tabindex');

            if (anchor) {
                anchor.scrollIntoView(true);
            }        },[]);

        return <div className={classes.gridContainer}>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                spacing={3}
            >
                {data.filter(a=>a.name===hit)
                    .map(
                        (a,i) => <Grid key={i} item xs={12} sm={12} md={6} lg={4}>
                                <div className={classes.paper}>
                                    <div className={classes.photoContainer}>
                                        <CardMedia
                                            className={classes.photo}
                                            image={a.photo+'.png'}
                                            title={a.title}
                                        />
                                    </div>
                                    <div className={classes.title}>{a.desc}</div>
                                </div>
                        </Grid> )}
            </Grid>
        </div>
    }


    return (<div>
        {!data?.length ? <div style={{width: 'fit-content', margin: 'auto'}}><CircularProgress/></div> :
            <Container>
                <Switch>
                    <Route exact path="/work/:hit" component={Card}/>
                    <Route exact path={'/work'}>
                        <div className={classes.gridContainer}>
                            <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center"
                                spacing={4}
                            >
                                {data.reduce((p, a) => !p.length || p[p.length - 1].name !== a.name ? [...p, a] : p, [])
                                    .map(
                                        (a, i) => <Grid key={i} item xs={6} sm={6} md={4} lg={3}>
                                            <Link to={'/work/' + a.name} className={classes.link}>
                                                <div className={classes.paper}>
                                                    <div className={classes.photoContainer}>
                                                        <CardMedia
                                                            className={classes.photo}
                                                            image={a.photo + '.png'}
                                                            title={a.title}
                                                        />
                                                    </div>
                                                    <div className={classes.title}>{a.title}</div>
                                                </div>
                                            </Link>
                                        </Grid>)}
                            </Grid>
                        </div>

                    </Route>
                </Switch>

            </Container>
        }
    </div>)
}

