import React from "react";
import useStyles from "./styles";
import { faInstagram, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Grid } from '@material-ui/core';

export default function Footer() {
    const classes = useStyles();

    return <footer className={classes.footer}>
        <Grid container>
        <Grid item sm={12} className={classes['contenedor_footer']}>
            <Grid container spacing={2}>
                <Grid item xs={11} sm={4} className={classes['logo_footer']}>
                    <img src="https://i.imgur.com/qL9CGcc.png" width="150" alt=""/>
                </Grid>

                <Grid item xs={11} sm={4} className={classes["descripcion_footer"]}>
                    <h3>Estampas</h3>
                    <h2><FontAwesomeIcon icon={faHeart}/></h2>
                </Grid>

                <Grid item xs={11} sm={4} className={classes.social}>
                    {/*<a href="https://www.facebook.com/vivetmarket" target="_blanck"><FontAwesomeIcon icon={faFacebook}/></a>*/}
                    <a href="https://www.instagram.com/malumint/" target="_blanck"><FontAwesomeIcon icon={faInstagram}/></a>
                    <div className={classes["text_social"]}>
                        <p>Seguime</p>
                    </div>
                </Grid>
        </Grid>
        </Grid>
            <Grid item xs={12} className={classes.copy}>
                <p><span className={classes.copy_text}>2022 © MaluMint Todos los derechos reservados</span></p>
            </Grid>
        </Grid>
    </footer>
}
