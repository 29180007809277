import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import React from "react";
import useStyles from './styles';

export function GridData({data}) {
    const classes = useStyles();

    return <>
        <div className={classes.gridContainer}>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                spacing={3}
            >
                {data
                    .map(
                        (card,i) => <Grid key={i} item xs={6} sm={6} md={4} lg={3}>
                            <Link to={card.link} className={classes.link}>
                                <div className={classes.paper}>
                                    <div className={classes.photoContainer} style={card.borderRadius?{borderRadius:card.borderRadius}:{}}>
                                        <CardMedia
                                            className={classes.photo}
                                            image={card.photo+'.png'}
                                            title={card.title}
                                        />
                                    </div>
                                    <div className={classes.title}>{card['title']}</div>
                                </div>
                            </Link>
                        </Grid> )}
            </Grid>
        </div>
    </>
}
