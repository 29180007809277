import React from "react";
import {Link, Route, Switch, useLocation, withRouter,} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components

// pages
import Home from "../../pages/home";
import Work from "../../pages/work";
import Clients from "../../pages/clients";

// context
import {useLayoutState} from "../../context/LayoutContext";
import BackToTop from "./BackToTop";
import {Toolbar} from "@material-ui/core";
import TabIndex from "../tabIndex";
import Bottom from "./Bottom";
import ContactMe from "../../pages/contact";
import WhatsappButton from "./WhatsappButton";
import {ShopGallery} from "../../pages/shop";
import Footer from "../../components/Footer";

const pages= [
  {id:'work',name:'My Projects'},
  {id:'clients',name:'Clients'},
  {id:'shop',name:'Shop'},
  {id:'contact',name:'Contact Me'},
];

function Layout(props) {
  var classes = useStyles();

  // global

  let location = useLocation();
  let pathnames = location.pathname.split('/');
  pathnames.shift();

  return (
    <div className={classes.root}>
        <div className={classes.body}>
          {/*<Header history={props.history} />*/}
          {/*<div className={classes.fakeToolbar} id="fakeToolbar"/>*/}

          <div id={"backToTopAnchor"}/>
          <Link to={"/home"}>
          <div className={classes.logo_wrap}>
            <div className={classes.logo_image_container}>
              <img className={classes.logo_image} src={"https://i.imgur.com/qL9CGcc.png"} alt={'malumint'}/>
            </div>
          </div>
          </Link>
          <Toolbar>
            <div className={classes.pages}>
              {pages.map(page=><Link key={page.id} to={'/'+page.id} className={classnames(classes.pageLink,{[classes.pageSelected]:page.id===pathnames[0]})}>{page.name}</Link> )}
            </div>
          </Toolbar>
          <div
            className={classes.content}
          >
            <TabIndex />
            <Switch>
              <Route path="/home" component={Home} />
              <Route path="/work" component={Work} />
              <Route path="/clients" component={Clients} />
              <Route path="/contact" component={ContactMe} />
              <Route path="/shop" component={ShopGallery} />
            </Switch>
            <BackToTop/>
            <WhatsappButton/>
          </div>
          <Bottom/>

        </div>
      <Footer/>
    </div>
  );
}


export default withRouter(Layout);


