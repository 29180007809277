import {useEffect, useState} from "react";


export default function SheetFetch({url}) {
    const [data,setData] = useState([]);

    useEffect(()=>{
        const myAbortController = new AbortController();
        fetch(url+'&json=true', { signal: myAbortController.signal })
            .then(response=>response.ok ? response.json() : Promise.reject(response.status))
            .then(r=>setData(r));
        return myAbortController.abort;
    },[url]);
    return data;
}
