import {initializeApp} from "firebase/app";
import {addDoc, collection, getFirestore, serverTimestamp} from 'firebase/firestore/lite';


// const Timestamp = firebase.firestore.Timestamp
// const FieldValue = firebase.firestore.FieldValue;

const firebaseConfig = {
    apiKey: "AIzaSyBPVY5KL7DPIkUqa9DB4rSCHHPh6FW1Wys",
    authDomain: "malumint-9188c.firebaseapp.com",
    databaseURL: "https://malumint-9188c.firebaseio.com",
    projectId: "malumint-9188c",
    storageBucket: "malumint-9188c.appspot.com",
    messagingSenderId: "425993904083",
    appId: "1:425993904083:web:fe3533ab1b47dc7c0a5926"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

// const updateDoc = (collection, id, hit, delete_fields) => {
//     let indexName = collection
//     typeof collection === 'object' && ({ indexName, id, hit, delete_fields } = collection)
//     if (!hit) hit = {}
//     if (delete_fields) for (let i in delete_fields) hit[delete_fields[i]] = firebase.firestore.FieldValue.delete()
//     if (uid) hit.lastEditBy = uid
//     return db.collection(indexName).doc(id).update({ algoliaUpdated: false, ...hit })
// }

const newDoc = ({ indexName, hit }) => {
    if (!hit) hit = {}
    // if (uid) hit.lastEditBy = uid
    // return db.collection(indexName).add({
    //     algoliaUpdated: false,
    //     modified_date: firebase.firestore.Timestamp.fromDate(new Date()), ...hit
    // })

    return addDoc(collection(db, indexName), {
        algoliaUpdated: false,
        created_date: serverTimestamp(),
        modified_date: serverTimestamp(), ...hit
    });
}

// export function useCollection({ collection = '', where = [], limit = 1, orderBy = [] }) {
//
//     const [snapshot, loading, error] = firestoreUseCollection(
//         orderBy.reduce((o, a) => o.orderBy(...a), where.reduce((o, a) => o.where(...a), db.collection(collection))).limit(limit))
//     const result = snapshot ? snapshot.docs.map((entity) => {
//         return { ...entity.data(), objectID: entity.id }
//     }) : undefined
//
//     if (result) {
//         result.forEach(element => {
//             Object.entries(element).forEach(([field, value]) => {
//                 if (value instanceof Timestamp) {
//                     element[field] = value.toDate()
//                 }
//             })
//         })
//     }
//
//     return [result, loading, error]
// }
//
//
// export function useDocumentData(collection, docID) {
//     const [entity, loading, error] = firestoreUseDocumentData(db.collection(collection).doc(docID))
//
//     let result = entity
//
//     if (result) {
//         result.objectID = docID
//         Object.entries(result).forEach(([field, value]) => {
//             if (value instanceof Timestamp) {
//                 result[field] = value.toDate()
//             }
//         })
//     }
//
//     return [result, loading, error]
// }
//
// export function getFirestoreCollection(collection) {
//     return db.collection(collection)
// }

export { newDoc, serverTimestamp }
