import React from 'react';
// import InstagramFeed from "../InstagramFeed";


export default function Bottom(){

    // useEffect(()=>{
    //     setTimeout(()=>{
    //     new InstagramFeed({
    //         'username': 'malumint',
    //         'container': document.getElementById("instaFeed"),
    //         'display_profile': true,
    //         'display_biography': true,
    //         'display_gallery': true,
    //         'callback': ()=>{
    //             let userEl = document.getElementsByClassName("instagram_username")[0].childNodes[1]
    //             if(userEl && userEl.style) {
    //                 userEl.style.color = '#339191';
    //                 userEl.style['text-decoration'] = 'none';
    //             }
    //         },
    //         'styling': true,
    //         'items': 6,
    //         'items_per_row': 3,
    //         'margin': 1,
    //         'lazy_load': true,
    //         'on_error': console.error
    //     });}
    // ,0)
    // },[])

    return <>
        {/*<div style={{*/}
        {/*    marginTop: '80px',*/}
        {/*marginBottom: '5px',*/}
        {/*marginLeft: 'auto',*/}
        {/*    marginRight: 'auto',*/}
        {/*    width: 'fit-content',*/}
        {/*}}>*/}
        {/*    <a*/}
        {/*        href={'//instagram.com/malumint'}*/}
        {/*    >*/}
        {/*    <img src={instagramLogo} alt={'instagram'} style={{height:'40px'}}/>*/}
        {/*    /!*<Typography color={"secondary"} style={{*!/*/}
        {/*    /!*    // "color": "white",*!/*/}
        {/*    /!*    "textDecoration": "unset",*!/*/}
        {/*    /!*    "fontWeight": "bold",*!/*/}
        {/*    /!*    "fontSize": "larger",}}>/malumint</Typography>*!/*/}
        {/*</a>*/}
        {/*</div>*/}
        {/*<div style={{ display: 'flex', justifyContent: 'center', maxWidth:400, margin: '50px auto auto' }}>*/}
        {/*    <InstagramEmbed url="https://www.instagram.com/p/CfcP7Q5AtGm/" width={'100%'} />*/}
        {/*</div>*/}
        {/*<div*/}
        {/*    id={'instaFeed'}*/}
        {/*    style={{color:'#339191',marginTop: '80px'}}*/}
        {/*></div>*/}
</>
}
