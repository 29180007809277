import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex:10,
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

function Whatsapp(props) {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        event.stopPropagation();

    };

    return (
        <Zoom in={!trigger}>
            <a href="https://api.whatsapp.com/send?phone=5491167006214&amp;text=%C2%A1Hola%21+Estoy+en+malumint.com" target="_blank" rel="noopener noreferer nofollow">
            <div role="presentation" className={classes.root}>
                {children}
            </div>
            </a>
        </Zoom>
    );
}


export default function WhatsappButton(props) {
    return (
            <Whatsapp {...props}>
                <Fab color="secondary" size="small" aria-label="scroll back to top">
                    <WhatsAppIcon />
                </Fab>
            </Whatsapp>
    );
}
