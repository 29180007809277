
import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    "footer": {
        "width": "100%",
        position: 'absolute',
        left: 0,
        bottom: 0,
},
    "contenedor_footer": {
        "borderBottom": "1px solid #d6d6d6",
        "margin": "auto",
},
    "logo_footer": {
    "textAlign": "center",
        "margin": "auto",
        "alignItems": "center",
        "justifyContent": "center",
        "& img": {
            "transition": "0.5s all",
            "&:hover":{
                "transform": "scale(1.060)"
            }
        }
},
    "descripcion_footer": {
    "textAlign": "center",
        "alignItems": "center",
        "justifyContent": "center",
        "margin": "auto",
        "lineHeight": "20px",
        "cursor": "default",
        "color": "#ffffff",
        "& h3":{
            "textTransform": "uppercase",
            "fontWeight": "500",
            "color": "#ffffff"
        },
        "& h2":{
            "color": "#ffffff"

        }
},
    "social": {
    "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "margin":"auto",
        "color": "#ffffff",
        "& a":{
            "textAlign": "center",
            "margin": "auto 0px",
            "color": "#ffffff",
            "&:hover": {
                "boxShadow": "6px 10px 10px #c9c9c9"

            }
        },
        "& svg":{
            // "background": "#fff",
            "color": "#ffffff",
            "fontSize": "16px",
            // "padding": "17px 20px",
            "borderRadius": "50%",
            "boxShadow": "4px 5px 5px #c9c9c9",
            "marginRight": "6px"
        }
},
    "text_social": {
    "textAlign": "center",
        "color": "#ffffff",

        "marginLeft": "10px",
        "cursor": "default",
        "& p":{
            "textAlign": "left",
            "color": "#ffffff",
        }
},
    "copy": {
        "textAlign": "center",
        "marginTop": "5px",
        "bottom": "0px",
        "& a": {
            "color": "#ffffff",
            "fontSize": "14px"
        }
    },
    "copy_text": {
        "color": "#ffffff",
        "fontSize": "14px",
        "bottom": "0px"
    }
}))
