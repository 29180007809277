import {makeStyles} from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    backgroundColor: 'rgb(171 171 171 / 34%)',
    borderRadius: '50px',
    paddingBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
    }
  },
  mockup: {
    width: '100%',
    height: '100%',
    left: 0,
    position: 'absolute',
  },

//   gridContainer: {
//     flexGrow: 1,
//   },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxWidth: '500px',
    margin: 'auto',
    marginTop: '1rem'
  },
  paperMockup: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxWidth: '700px',
    margin: 'auto',
    marginBottom: '1rem',
    marginTop: '1rem'
  },
//   link: {
//     textDecoration: 'none',
//   },
//   photo: {
//     width: '100%',
//     height: '100%',
//     left: 0,
//     position: 'absolute',
// },
  photoContainer: {
    position: 'relative',
    width: "100%",
    paddingBottom: "100%",
    overflow: 'hidden',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: '20px',
  },
  multipleImagesContainer: {
    position: 'relative',
    width: "95%",
    margin: 'auto',
    paddingBottom: "95%",
    overflow: 'hidden',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: '20px',
  },
//   title: {
//     "marginTop": "0.5rem",
//     "color": "white",
//     "textDecoration": "none",
//     "fontWeight": "bold",
//     "fontSize": "larger",
//     lineHeight: 1,
//   }

}));
