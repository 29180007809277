import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {LayoutProvider} from "./context/LayoutContext";
import {ThemeProvider} from "@material-ui/styles";
import {CssBaseline} from "@material-ui/core";
import Themes from "./themes";
import {BrowserRouter, Route, Switch} from "react-router-dom";

const PanelComponent = (
    lazy(() => (
        import('./pages/panel')
    ))
);


ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path={"/app"} render={()=><Suspense fallback={<div>Cargando Malumint Panel</div>}>
                <PanelComponent/>
            </Suspense>}/>
            <Route path={"/"} render={()=>(
                <LayoutProvider>
                    <ThemeProvider theme={Themes.default}>
                        <CssBaseline />
                        <App />
                    </ThemeProvider>
                </LayoutProvider>
            )
            }/>
        </Switch>
    </BrowserRouter>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
