import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import SheetFetch from "../../components/sheetFetch";
import {Switch,Route} from "react-router-dom";
import {GridData} from "../../components/Cards";
import React from "react";
import ShopItem from "./shopItem";
import CircularProgress from "@material-ui/core/CircularProgress";

const url = 'https://script.google.com/macros/s/AKfycbw1tdbghKWMpfwYjR4GwSXe9Ia4XWmZK9g3FRUimGtCv8qPnIq4/exec?sheet=shop';

export function ShopGallery() {
    const data = SheetFetch({url});

    return (
        <Switch>
            <Route exact path="/shop">
                <div style={{width: 'fit-content', maxWidth: '80%', margin: 'auto', marginBottom:'2rem'}}>
                    <img style={{width:'100%',maxWidth:'500px'}} src={'https://i.imgur.com/iMmK7gj.png'} alt={'descripcion'} />
                </div>
                {!data.length ? <div style={{width: 'fit-content', margin: 'auto'}}><CircularProgress/></div>
                    : <>
                    <GridData data={data.map(c => ({...c, link: c.link || `/shop/${c.id}`}))}/>
                    </>
                }
            </Route>
            <Route path="/shop/:hit" component={props=>ShopItem({card:data.find(d=>d.id===props.match.params.hit)})}/>
        </Switch>

    );
}
