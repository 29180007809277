import {makeStyles} from "@material-ui/styles";

export default makeStyles(theme => ({
  form: {
    width: 'fit-content',
      margin: 'auto',
    '& > *': {
      margin: theme.spacing(1),
      // width: '25ch',
    },
  },
  form2: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up(600)] : {
      '& > *': {
        margin: theme.spacing(1),
      },
    }
  },
  charlemos: {
    margin: 'auto',
    marginTop: theme.spacing(3),
    width: 'fit-content',
  },
  charlemosImage: {
    maxWidth: '130px',
  },
  graciasPorElMensaje: {
    margin: 'auto',
    marginTop: theme.spacing(3),
    width: 'fit-content',
    backgroundColor:' '
  }

}));
