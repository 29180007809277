import {makeStyles} from "@material-ui/styles";

export default makeStyles(theme => ({
  gridContainer: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  link: {
    textDecoration: 'none',
  },
  photo: {
    width: '100%',
    height: '100%',
    left: 0,
    position: 'absolute',
},
  photoContainer: {
    position: 'relative',
    width: "100%",
    paddingBottom: "100%",
    overflow: 'hidden',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: '20px',
  },
  title: {
    "marginTop": "0.5rem",
    "color": "white",
    "textDecoration": "none",
    "fontWeight": "bold",
    "fontSize": "larger",
    lineHeight: 1,
  }

}));
