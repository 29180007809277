import React from "react";
import {Link, useLocation} from 'react-router-dom';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        margin: '0 0 2rem 2rem',
    },
    pageLink: {
        "display": "inline-block",
        "paddingRight": "1rem",
        "color": "white",
        "textDecoration": "unset",
        "fontWeight": "bold",
        "fontSize": "larger",
        '&:last-child': {
            paddingRight: '0',
        },
        [theme.breakpoints.down('sm')]: {
            "paddingRight": "1rem",
        },
    }
}))

export default function TabIndex(){
    const classes = useStyles();

    let location = useLocation();
    let pathnames = location.pathname.split('/');
    pathnames.shift();

    return pathnames[1] ? <div className={classes.root} id={'tabindex'}>
        {/* eslint-disable-next-line no-useless-escape */}
        {pathnames.map((page,i)=><Link key={page} to={pathnames.reduce((p,a,j)=>j<=i?p+'/'+a:p,'')} className={classes && classes.pageLink}>{capitalize(page).replace(/-/g,' ')} {pathnames.length > (i+1) && '>'}</Link>)}
    </div> : <div className={classes.root} id={'tabindex'}/>

}


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
