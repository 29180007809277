import {makeStyles} from "@material-ui/styles";

export default makeStyles(theme => {
  console.log(theme);
  return ({
    root: {
      // display: "flex",
      maxWidth: "100vw",
      overflowX: "hidden",
      overflowY: "hidden",
      position: "relative",
    },
    body: {
      minHeight: "100vh",
      paddingBottom: "200px",
      [theme.breakpoints.down('sm')]: {
        paddingBottom: "400px",
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      marginTop: theme.spacing(1),
      width: `calc(100vw)`,
      minHeight: '300px',
      // minHeight: `calc(100vh - 64px)`,
      // marginTop: `64px`,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    contentShift: {
      width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    fakeToolbar: {
      ...theme.mixins.toolbar,
    },
    "logo_wrap": {
      "maxWidth": "100%",
      "marginLeft": "auto",
      "marginRight": "auto",
      "marginTop": "40px",
      "paddingBottom": "0px",
      "paddingTop": "0px",
      "width": "100%"
    },
    logo_image_container: {
      "marginLeft": "auto",
      "marginRight": "auto",
      "textAlign": "center"
    },
    "logo_image": {
      width: '200px',
      maxWidth: '50%',
    },
    pages: {
      maxWidth: '100%',
      margin: 'auto',
      marginTop: '2rem',
    },
    pageLink: {
      "display": "inline-block",
      "paddingRight": "50px",
      "color": "white",
      "textDecoration": "unset",
      "fontWeight": "bold",
      "fontSize": "1rem",
      '&:last-child': {
        paddingRight: '0',
      },
      [theme.breakpoints.down('xs')]: {
        "paddingRight": "1rem",
        "fontSize": "0.8rem",
      },
    },
    pageSelected: {
      "color": theme.palette.text.secondary,
    },

  })
});
