import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import CardMedia from "@material-ui/core/CardMedia";
import {Button, TextField, useMediaQuery} from "@material-ui/core";

import useStyle from './styles'
import {newDoc, serverTimestamp} from '../../firebase'

export default function ContactMe() {
    const classes = useStyle();
    const [formData,setFormData] = useState({});
    const mobile = useMediaQuery(theme=>theme.breakpoints.down(600));

    const handleSend = async () => {
        await newDoc({ indexName:'webMessages', hit:{...formData,date:serverTimestamp()} });
        setFormData({sent:true});
    }

    return (<div>

        <Container>
            <Box my={2}
                 style={{
                     maxWidth: "600px",
                     margin: 'auto',
                 }}
            >
                <a href = {"mailto: info@malumint.com"} target="_blank" rel={"noreferrer"}>
                    <CardMedia
                        // className={classes.photo}
                        image={'https://i.imgur.com/ebOkj46.png'}
                        title={'contact Me'}
                        style={{
                            paddingBottom:'66%',
                            "display": "block",
                            "backgroundSize": "contain",
                            "backgroundRepeat": "no-repeat",
                            "backgroundPosition": "center",
                        }}
                    />
                </a>
                {/*<img src={aboutmePNG} alt={'about Me'}/>*/}
            </Box>
            { formData.sent ? <Box>Gracias por tu mensaje, pronto me pondre en contacto</Box> : <>
                <div className={classes.charlemos}>
                    <img className={classes.charlemosImage} src={'https://i.imgur.com/AYDKrbO.png'} alt={'charlemos'}/>
                </div>
                <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.form2} >
                        <TextField fullWidth={mobile} onChange={e => setFormData(d => ({...d, name: e.target.value}))} label="Nombre"
                                   variant="filled"/>
                        <TextField fullWidth={mobile} onChange={e => setFormData(d => ({...d, email: e.target.value}))} label="Email"
                                   variant="filled"/>
                        <TextField fullWidth={mobile} onChange={e => setFormData(d => ({...d, title: e.target.value}))} label="Asunto"
                                   variant="filled"/>
                    </div>
                    <div>
                        <TextField onChange={e => setFormData(d => ({...d, message: e.target.value}))} label="Mensaje"
                                   variant="filled" fullWidth multiline minRows={4}/>
                    </div>
                    <Button fullWidth onClick={handleSend} disabled={!['name','email','title','message'].reduce((p,a)=>p&&!!formData[a],true)}>Enviar</Button>
                </form>
            </>
            }
        </Container>
    </div>)
}
