import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {CardMedia} from "@material-ui/core";
import useStyle from './styles'


export default function Home() {
    const classes = useStyle();

    return (<><div>

        <Container>
            <Box my={2}
                 style={{
                     maxWidth: "600px",
                     margin: 'auto',
                 }}
            >
                 <CardMedia
                    image={'https://i.imgur.com/r9eJ0bY.jpg'}
                    title={'about Me'}
                    className={classes.homePicture}
                />
                 <CardMedia
                    image={'https://i.imgur.com/RoZUCDU.png'}
                    title={'about Me'}
                    className={classes.homePicture}
                />
            </Box>
        </Container>
    </div>
        <div style={{maxWidth: 'fit-content',margin: 'auto'}}>
            <a style={{maxWidth: 'fit-content',margin: 'auto',color: '#ffffff',textDecoration:'none'}} href="//instagram.com/malumint">
                <h3>SEGUIME EN INSTAGRAM <img src="/glyph-logo_May2016.png" style={{height: '15px'}} alt={'Malumint'}/></h3>
            </a>
        </div>
        {/*<div style={{maxWidth: '600px', margin: 'auto'}}>*/}
        {/*    <iframe src="//lightwidget.com/widgets/0da5f2281bc5559196a4eb692dd8deed.html" scrolling="no"*/}
        {/*            allowTransparency="true" className="lightwidget-widget"*/}
        {/*            style={{width: '100%',border: 0,overflow: 'hidden'}}>*/}
        {/*    </iframe>*/}
        {/*</div>*/}
        </>)
}
