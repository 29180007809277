import {makeStyles} from "@material-ui/styles";

export default makeStyles(theme => ({
  homePicture: {
    cursor:'pointer',
    paddingBottom:'66%',
    "display": "block",
    "backgroundSize": "contain",
    "backgroundRepeat": "no-repeat",
    "backgroundPosition": "center",
    [theme.breakpoints.down(600)]:{
      paddingBottom:'90%',
    }
  }

}));
