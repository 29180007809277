import CardMedia from "@material-ui/core/CardMedia";
import {Carousel} from "react-responsive-carousel";
import React from "react";
import useStyles from './styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Button, Box, Typography, Grid} from "@material-ui/core";


export default function ShopItem({card}) {
    const classes = useStyles();
    console.log(Object.entries(card || {}).filter(([k]) => k.includes('image')));
    const images = Object.entries(card || {}).filter(([k, v]) => k.includes('image') && v).map(([, v]) => ({
        original: v + '.png',
        thumbnail: v + '.png'
    }));

    return <>
        {!card ? <Box style={{width: 'fit-content', margin: 'auto'}}><CircularProgress/></Box> :
            <Grid className={classes.root} container>
                <Grid className={classes.paperMockup} item sm={6} xs={12}>
                    <Box className={classes.photoContainer}
                         style={card.borderRadius ? {borderRadius: card.borderRadius} : {}}>
                        <CardMedia
                            className={classes.mockup}
                            image={card.mockup + '.png'}
                            title={card.title}
                        />
                    </Box>
                </Grid>
                <Grid className={classes.paper} item sm={6} xs={12}>
                    {images.length === 1 ?
                        <Box className={classes.photoContainer} style={card.borderRadius ? {borderRadius: card.borderRadius} : {}}>
                            <CardMedia
                                className={classes.mockup}
                                image={images[0].original}
                                title={card.title}
                            /></Box>
                        : images.length > 1 &&
                        <Carousel
                            showThumbs={true}
                            showStatus={false}
                            showIndicators={false}
                            showArrows={true}
                            stopOnHover={true}
                            renderThumbs={() => images.map((value, index) =>
                                <img key={index} src={value.thumbnail}
                                     alt={value.original}/>)}
                        >
                            {images.map((image, index) => (
                                <Box key={index} className={classes.multipleImagesContainer} style={card.borderRadius ? {borderRadius: card.borderRadius} : {}}>
                                    <CardMedia
                                        className={classes.mockup}
                                        image={image.original}
                                        title={card.title}
                                    /></Box>
                            ))}
                        </Carousel>
                    }
                </Grid>
                <Grid style={{width: 'fit-content', margin: 'auto',padding:'1rem'}} item sm={6} xs={12}>
                    <Typography variant="h2" style={{color:'#fff', fontWeight:400, textAlign: 'justify'}}>
                        {card.title}
                    </Typography>
                    <Typography style={{color:'#fff', fontWeight:400, textAlign: 'justify'}}>
                        {card.description}
                    </Typography>
                </Grid>
                <Grid className={classes.paper} item sm={6} xs={12}>
                    <Button variant={'contained'} color={'secondary'}
                            style={{'& span':{fontFamily: 'letter_magicregular'}}}
                            href={'https://api.whatsapp.com/send?phone=5491167006214&text=%C2%A1Hola%21%20Estoy%20en%20malumint.com%2C%20me%20interesa%20este%20dise%C3%B1o%3A%20%0Ahttps%3A%2F%2Fmalumint.com%2Fshop%2F' + card.id}
                            target={'_blank'}>
                        Quiero este!
                    </Button>
                </Grid>
            </Grid>
        }
    </>
}
