import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import SheetFetch from "../../components/sheetFetch";
import {Link, Route, Switch} from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from '@material-ui/core/CircularProgress';


// const url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRd96ByJ62JaGj8fVEvyVCNKkttuTnPPlFvKD-ZFFpMRXd0r_PxaqLejX6j_AY12NKaFpS7QAA_gjCz/pub?gid=364831155&single=true&output=csv';

const url = 'https://script.google.com/macros/s/AKfycbw1tdbghKWMpfwYjR4GwSXe9Ia4XWmZK9g3FRUimGtCv8qPnIq4/exec?sheet=clients';


export default function Clients() {
    const classes = useStyles();

    const data = SheetFetch({url});


    function Card(props) {
        let hit = props.match.params.hit;
        const hits = data.filter(a=>a.category.toLowerCase().replace(/ /g,'-')===hit);

        useEffect(()=>{

            const anchor = document.querySelector('#tabindex');

            if (anchor) {
                anchor.scrollIntoView(true);
            }
        },[])

        return <Switch>
            <Route exact path={'/clients/'+hit}>
                <GridData classes={classes} data={hits} filter={'name'} hit={hit} title={'title'}/>
            </Route>
            <Route path={"/clients/:mhit/:hit"} component={CardCard}/>
        </Switch>
    }

    function CardCard(props) {
        let hit = props.match.params.hit;
        let mhit = props.match.params.mhit;
        const hits = data.filter(a=>a.category.toLowerCase().replace(/ /g,'-')===mhit).filter(a=>a.name.toLowerCase().replace(/ /g,'-')===hit);

        useEffect(()=>{
            const anchor = document.querySelector('#tabindex');

            if (anchor) {
                anchor.scrollIntoView(true);
            }        },[])

        return <>

                <div className={classes.gridContainer}>
                    <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                        spacing={3}
                    >
                        {hits
                            .map(
                                (a,i) => <Grid key={i} item xs={12} sm={12} md={6} lg={4}>
                                    <div className={classes.paper}>
                                        <div className={classes.photoContainer} style={a.borderRadius&&hit?{borderRadius:a.borderRadius}:{}}>
                                            <CardMedia
                                                className={classes.photo}
                                                image={a.photo+'.png'}
                                                title={a.title}
                                            />
                                        </div>
                                        <div className={classes.title}>{a.desc}</div>
                                    </div>
                                </Grid> )}
                    </Grid>
                </div>
                </>
    }


    return (<div>
            <Container>
                <Switch>
                    <Route exact path={'/clients'}>
                        <div style={{width: 'fit-content', maxWidth: '80%', margin: 'auto', marginBottom:'2rem'}}>
                            <img style={{width:'100%',maxWidth:'500px'}} src={'https://i.imgur.com/ZvCVVL3.png'} alt={'descripcion'} />
                        </div>
                        {!data?.length ? <div style={{width: 'fit-content', margin: 'auto'}}><CircularProgress/></div> :
                            <GridData classes={classes} data={data} filter={'category'} title={'category'}/>
                        }
                    </Route>
                    <Route path="/clients/:hit" component={Card}/>
                </Switch>

            </Container>
    </div>)
}


function GridData({classes,data,filter,hit,title}) {

    return <>
        <div className={classes.gridContainer}>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                spacing={3}
            >
                {data.sort((a,b)=>a[filter]-b[filter]).reduce((p,a)=> !p.length || p[p.length-1][filter] !== a[filter] ? [...p,a] : p,[])
                    .map(
                        (a,i) => <Grid key={i} item xs={6} sm={6} md={4} lg={3}>
                            <Link to={'/clients/'+(hit?hit+'/':'')+a[filter].toLowerCase().replace(/ /g,'-')} className={classes.link}>
                                <div className={classes.paper}>
                                    <div className={classes.photoContainer} style={a.borderRadius&&hit?{borderRadius:a.borderRadius}:{}}>
                                        <CardMedia
                                            className={classes.photo}
                                            image={a.photo+'.png'}
                                            title={a.title}
                                        />
                                    </div>
                                    <div className={classes.title}>{a[title]}</div>
                                </div>
                            </Link>
                        </Grid> )}
            </Grid>
        </div>
        </>
}
